import { AllContentRoles } from "@/constants/userRoles";
export default [
  {
    path: "/content/dashboard",
    component: () =>
      import(/* webpackChunkName: 'ContentDashboard' */ "@/views/content/ContentDashboard.vue"),
    name: "ContentDashboard",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/create-preloaded-assignment",
    component: () =>
      import(
        /* webpackChunkName: 'CreatePreloadedAssignment' */ "@/views/content/CreatePreloadedAssignment.vue"
      ),
    name: "CreatePreloadedAssignment",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/create-preloaded-test",
    component: () =>
      import(
        /* webpackChunkName: 'CreatePreloadedTest' */ "@/views/content/CreatePreloadedTest.vue"
      ),
    name: "CreatePreloadedTest",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/extract-pdf-questions",
    component: () =>
      import(
        /* webpackChunkName: 'ExtractPdfQuestions' */ "@/views/content/ExtractPdfQuestions.vue"
      ),
    name: "ExtractPdfQuestions",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/fix-question",
    component: () =>
      import(/* webpackChunkName: 'FixQuestion' */ "@/views/content/FixQuestion.vue"),
    name: "FixQuestion",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/review-course-content",
    component: () =>
      import(
        /* webpackChunkName: 'ReviewCourseContent' */ "@/views/content/ReviewCourseContent.vue"
      ),
    name: "ReviewCourseContent",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/tag-new-questions",
    component: () =>
      import(/* webpackChunkName: 'TagNewQuestions' */ "@/views/content/TagNewQuestions.vue"),
    name: "TagNewQuestions",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/teaching-content-editor",
    component: () =>
      import(
        /* webpackChunkName: 'TeachingContentEditor' */ "@/views/content/TeachingContentEditor.vue"
      ),
    name: "TeachingContentEditor",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
  {
    path: "/content/course-chapter-mapping",
    component: () =>
      import(
        /* webpackChunkName: 'TeachingContentEditor' */ "@/views/content/CourseChapterMapping.vue"
      ),
    name: "CourseChapterMapping",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllContentRoles,
    },
  },
];
