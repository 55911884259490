<template>
  <div class="navbar-menu">
    <el-menu
      v-if="navbarMenuItems"
      :router="true"
      :unique-opened="true"
      :mode="navbarMode"
      :ellipsis="false"
    >
      <div v-for="menuItem in navbarMenuItems">
        <el-menu-item
          v-if="!menuItem.ChildItems || menuItem.ChildItems.length === 0"
          :index="menuItem.ViewRoute"
        >
          <template #title>
            <el-icon>
              <img
                v-if="menuItem.MenuIcon"
                :src="getNavbarIconUrl(menuItem.MenuIcon)"
                class="navbar-item-icon me-2"
              />
            </el-icon>
            <span
              v-if="navbarMode !== NavbarModeEnum.HORIZONTAL || !menuItem.HideTitleOnHorizontal"
            >
              {{ menuItem.DisplayTitle }}
            </span>
          </template>
        </el-menu-item>
        <el-sub-menu v-else :index="menuItem.MenuName">
          <template #title>
            <el-icon>
              <img
                v-if="menuItem.MenuIcon"
                :src="getNavbarIconUrl(menuItem.MenuIcon)"
                class="navbar-item-icon me-2"
              />
            </el-icon>

            <span
              v-if="navbarMode !== NavbarModeEnum.HORIZONTAL || !menuItem.HideTitleOnHorizontal"
              >{{ menuItem.DisplayTitle }}</span
            >
          </template>
          <el-menu-item
            v-for="childItem in menuItem.ChildItems"
            :index="childItem.ViewRoute"
            :disabled="childItem.IsLocked"
          >
            <el-icon>
              <img
                v-if="menuItem.MenuIcon"
                :src="getChildMenuIcon(menuItem, childItem)"
                class="navbar-child-item-icon me-2"
              />
            </el-icon>
            <span>{{ childItem.DisplayTitle }}</span>
          </el-menu-item>
        </el-sub-menu>
      </div>
    </el-menu>
  </div>
</template>

<script lang="ts">
import { NavbarModeEnum } from "@/enums/global";
import { getNavbarIconUrl } from "@/methods/utils";
import { MenuItem, NavbarMenuItem } from "@/types/global";
import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: "TheNavbarMenu",
  props: {
    navbarMenuItems: { type: Object as PropType<NavbarMenuItem[]>, required: true },
    navbarMode: { type: String },
  },
  setup(props) {
    function getChildMenuIcon(menuItem: NavbarMenuItem, childItem: MenuItem) {
      let menuIconName = childItem.MenuIcon ? childItem.MenuIcon : menuItem.MenuIcon;
      if (menuIconName && props.navbarMode === NavbarModeEnum.HORIZONTAL) {
        menuIconName = `${menuIconName}-primary`;
      }
      return menuIconName ? getNavbarIconUrl(menuIconName) : undefined;
    }
    return { NavbarModeEnum, getNavbarIconUrl, getChildMenuIcon };
  },
});
</script>

<style lang="scss" scoped>
.navbar-child-item-icon {
  height: 16px;
  margin-right: 5px;
  @include media("<=l") {
    height: 14px;
  }
}

.navbar-menu {
  & .navbar-item-icon {
    height: 24px;
    margin-right: 5px;
    @include media("<=l") {
      height: 20px;
    }
  }

  & .el-menu {
    --el-menu-active-color: #{$color-white};
    --el-menu-text-color: #{$color-white};
    --el-menu-hover-text-color: #{$color-white};
    --el-menu-bg-color: #{$color-primary};
    --el-menu-hover-bg-color: #{$color-primary-light};
    --el-menu-item-height: 56px;
    --el-menu-sub-item-height: calc(var(--el-menu-item-height) - 6px);
    --el-menu-horizontal-height: 56px;
    --el-menu-horizontal-sub-item-height: 36px;
    --el-menu-item-hover-fill: #{$color-white};
    --el-menu-border-color: #{$color-primary};
    --el-menu-base-level-padding: 20px;
    --el-menu-level-padding: 20px;
    --el-menu-icon-width: 24px;

    @include media("<=l") {
      --el-menu-base-level-padding: 10px;
      --el-menu-level-padding: 10px;
      --el-menu-horizontal-height: 48px;
      --el-menu-item-height: 48px;
      --el-menu-item-font-size: #{$font-size-xxs};
    }
  }
}
</style>
