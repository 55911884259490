<template>
  <div class="footer flexcol ycenter bg-white">
    <div class="flexrow xbetween px-3">
      <div class="fs-s flexrow xstart ycenter">
        <i class="bi bi-c-circle-fill color-primary fs-m me-2"></i>
        <span>{{ CompanyFullname }} {{ copyrightYear }}</span>
      </div>
      <div class="flexrow xcenter ycenter gap-3">
        <a v-for="footerLink in footerLinks" :href="footerLink.link" target="_blank">
          <i :class="`bi bi-${footerLink.icon} color-primary fs-m`"></i>
        </a>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  CompanyFullname,
  FacebookUrl,
  TwitterUrl,
  LinkedInUrl,
  YoutubeUrl,
  InstagramUrl,
} from "@/constants/global";
import { defineComponent } from "vue";

export default defineComponent({
  name: "TheAppFooter",
  setup() {
    const footerLinks = [
      { link: FacebookUrl, icon: "facebook" },
      { link: TwitterUrl, icon: "twitter" },
      { link: LinkedInUrl, icon: "linkedin" },
      { link: YoutubeUrl, icon: "youtube" },
      { link: InstagramUrl, icon: "instagram" },
    ];
    const copyrightYear = new Date().getFullYear();
    return { footerLinks, CompanyFullname, copyrightYear };
  },
});
</script>

<style lang="scss" scoped>
.footer {
  box-shadow: 0 0 4px 0 rgba($color-black, 0.2);
  height: 50px;
  @include media(">l") {
    position: fixed;
    width: 100%;
    bottom: 0;
  }
}
</style>
